import get from 'lodash/get';

const appSelectors = (state) => ({
  isWrtcConnected: get(state, 'app.remoting.lifesizeConnect.iceConnectionState') === 'connected',
  stream: get(state, 'app.remoting.lifesizeConnect.stream'),
  uuid: get(state, 'nucleus.userProfile.uuid'),
  isLinked: get(state, 'app.pairing.isLinked'),
  about: get(state, 'app.about'),
  roomName: get(state, 'app.remoting.controller.roomName'),
  pairingCode: get(state, 'app.pairing.pairingCode')
});

export default appSelectors;
