import wrtc from 'wrtc';
import createStore from './createStore';
import * as c from './constants';

export const init = async () => {
  console.log('Version: ', process.env.REACT_APP_VERSION);
  console.log('Build: ', process.env.REACT_APP_BUILD);

  const store = await createStore();
  
  store.dispatch({ type: c.app.SET_WRTC });
  setWrtc();

  return store;
};

const setWrtc = () => {
  window.RTCSessionDescription = wrtc.RTCSessionDescription;
  window.RTCPeerConnection = wrtc.RTCPeerConnection;
  window.RTCIceCandidate = wrtc.RTCIceCandidate;
};
