import React from 'react';
import Modal from 'react-modal';
import './About.css';

const About = (props) => {
  const getWebAppVersion = () => {
    return `Web App Version: ${process.env.REACT_APP_VERSION}`;
  };

  const getWebAppBuild = () => {
    return `Web App Build: ${process.env.REACT_APP_BUILD}`;
  };

  const getClientVersion = () => {
    return `Client Version: ${props.about?.clientVersion}`;
  };

  const getClientBuild = () => {
    return `Client Build: ${props.about?.clientBuild}`;
  };

  return (
    <Modal
      className="aboutModal"
      overlayClassName="aboutModalOverlay"
      isOpen={props.isAboutModalOpen}
      onRequestClose={props.onCloseAboutModal}
      shouldCloseOnOverlayClick={false}
    >
      <p className="aboutModalTitle">Lifesize Connect</p>
      <p className="aboutText">{getWebAppVersion()}</p>
      <p className="aboutText">{getWebAppBuild()}</p>
      <p className="aboutText">{getClientVersion()}</p>
      <p className="aboutText">{getClientBuild()}</p>
      <button className="aboutCancelButton" onClick={props.onCloseAboutModal}>Close</button>
    </Modal>
  );
};

export default About;
