import { createReducer } from '../utils/redux';
import { app } from '../constants';

export const initialState = {
  clientVersion: '',
  clientBuild: ''
};

export default createReducer(initialState, {
  [app.SET_ABOUT]: (state, action) => ({
    ...state,
    clientVersion: action.payload.clientVersion,
    clientBuild: action.payload.clientBuild
  })
});
