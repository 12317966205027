import { remotingActions } from '@lifesize/clients.remoting';
import { app, pairing } from '../constants';

const appActions = (dispatch) => ({
    togglePip: () => dispatch(remotingActions.remoting.sendToDisplay.app.togglePipPreview(true)),
    requestState: (uuid) => dispatch(remotingActions.remoting.requestState(uuid)),
    setAbout: (about) => dispatch({ type: app.SET_ABOUT, payload: about }),
    setPairingCode: (code) => dispatch({ type: pairing.SET_PAIRING_CODE, payload: code })
});

export default appActions;
