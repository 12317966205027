import { nucleusConstants } from '@lifesize/nucleus';
import { createReducer } from '../utils/redux';
import { pairing } from '../constants';

export const initialState = {
  isLinking: false,
  isUnlinking: false,
  isLinked: false,
  pairingError: '',
  pairingCode: ''
};

export default createReducer(initialState, {
  [pairing.SET_PAIRING_CODE]: (state, action) => ({
    ...state,
    pairingCode: action.payload
  }),
  [pairing.SUBMIT_CODE_BEGIN]: (state) => ({
    ...state,
    isLinking: true,
    pairingError: '',
  }),
  [pairing.SUBMIT_CODE_FAILURE]: (state, action) => ({
    ...state,
    isLinking: false,
    pairingError: action.payload
  }),
  [pairing.LOGIN_FAILURE]: (state, action) => ({
    ...state,
    isLinking: false,
    pairingError: action.payload
  }),
  [pairing.UNLINK_BEGIN]: (state) => ({
    ...state,
    isUnlinking: true
  }),
  [nucleusConstants.WAMP_CONNECT_SUCCESS]: (state) => ({
    ...state,
    isLinking: false,
    isLinked: true,
    pairingError: ''
  }),
  [pairing.UNLINK_SUCCESS]: () => initialState
});
