import { servicesActions } from '@lifesize/nucleus';
import { remotingActions, remotingConstants } from '@lifesize/clients.remoting';
import { pairing } from '../constants';

/**
 * Login the presenter into nucleus
 * @param store the store
 * @param jwt the javascript web token provided by the linking code
 */
export async function login(store, jwt) {
  try {
    /* Attempt to use the JWT provided.
     * if a jwt is provided, nucleus should log in with the jwt and ignore the cookie (a cookie may be present if a
     * presenter also uses the web app) */
    await store.dispatch(servicesActions.connect(jwt, true));
    /* a login failure should be caught in the 'catch' below */
    // subscribe to linking for the duration of the login (to listen for 'unpairing' event)
    await store.dispatch(remotingActions.pairing.subscribeToLinking());
    await store.dispatch(remotingActions.remoting.subscribeToEvents()); // subscribes to ls.deviceMessaging
  }
  catch (e) {
    // the error returned here is not useful to the end user, so replace it with something readable
    store.dispatch({ type: pairing.LOGIN_FAILURE, payload: 'Unable to connect, please try again' });
  }
}

const submitPairingCode = (dispatch, pairingCode) => {
  try {
    dispatch({ type: pairing.SUBMIT_CODE_BEGIN });
    dispatch(remotingActions.pairing.getAccountDetails(pairingCode, remotingConstants.pairing.linkedDeviceTypes.presenter));
  } catch (e) {
    dispatch({ type: pairing.SUBMIT_CODE_FAILURE, payload: 'Submit Error' });
  }
};

const pairingActions = (dispatch) => ({
  submitPairingCode: (pairingCode) => submitPairingCode(dispatch, pairingCode)
});

export default pairingActions;
