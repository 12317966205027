import React from 'react';
import './CameraControls.css';
import up from '../assets/chevron-up.svg';
import down from '../assets/chevron-down.svg';
import back from '../assets/chevron-back.svg';
import next from '../assets/chevron-next.svg';
import search from '../assets/search.svg';

const CameraControls = (props) => {
  const directionalControls = () => {
    return (
      <div className="directionalContainer">
        <div className="upIconContainer">
          <button className="directionalButton">
            <img src={up} alt="svg not found" width="26px" height="26px" />
          </button>
        </div>
        <div className="centerIconContainer">
          <button className="directionalButton">
            <img src={back} alt="svg not found" width="26px" height="26px" />
          </button>
          <button className="directionalButton">
            <img src={next} alt="svg not found" width="26px" height="26px" />
          </button>
        </div>
        <div className="downIconContainer">
          <button className="directionalButton">
            <img src={down} alt="svg not found" width="26px" height="26px" />
          </button>
        </div>
      </div>
    );
  };

  const zoomControls = () => {
    return (
      <div className="zoomContainer">
        <button className="leftDirectionalButton">
          <p className="zoomControl">-</p>
        </button>
        <div className='zoomCenter'>
          <img src={search} alt="svg not found" width="36px" height="36px" />
        </div>
        <button className="rightDirectionalButton">
          <p className="zoomControl">+</p>
        </button>
      </div>
    );
  };

  return (
    <div className="controlsContainer">
      <button className="backButton" onClick={() => props.hideControls()}>
        <img src={back} alt="svg not found" width="26px" height="26px" />
      </button>
      <p className="controlsTitle">UX Huddle Room 1</p>
      <p className="controlsSubtitle">Camera Controls</p>
      {directionalControls()}
      {zoomControls()}
    </div>
  );
};

export default CameraControls;
