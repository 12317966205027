import React from 'react';
import './Start.css';
import hdmi from '../assets/hdmi.svg';

const Start = (props) => {
  return (
    <div className="container">
      <p className="title">Lifesize Camera</p>
      <div className="content">
        <img src={hdmi} alt="svg not found" width="60px" height="60px" />
      </div>
      <p className="startLabel">Connect the HDMI cable to your laptop.</p>
      <p className="startLabel">Or enter the pairing code manually</p>
      <button className="button" onClick={props.onManualPairing}>Enter code</button>
    </div>
  );
};

export default Start;
