export const convertLinkingCode = (code) => {
    let cInt = parseInt(code, 10);
    const Cvt = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let finalCode = '';
    for (var i = 0; i < 6; i++) {
        const v = cInt % Cvt.length;
        finalCode += Cvt[v];
        cInt = Math.floor(cInt / Cvt.length);
    }

    return finalCode.split('').reverse().join('');
};
