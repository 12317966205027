import React, { useEffect, useState } from 'react';
import { isAlphaDigit, upperCase, truncate, chars } from 'voca';
import debounce from 'lodash/debounce';
import './Pairing.css';

const MAX_INPUT_LEN = 6;

const Pairing = (props) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    setInputBoxes(props.code);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.code]);

  const submitPairingCode = debounce((pairingCode) => {
    if (!props.isLinking) {
      props.submitPairingCode(pairingCode);
    }
  }, 500);

  const setInputBoxes = (edidCode) => {
    if (edidCode) {
      // Set the code characters for each box
      chars(edidCode).forEach((c, index) => {
        const inputBox = document.getElementById(index);
        inputBox.disabled = false;
        inputBox.focus();
        inputBox.value = c;

        if (index === MAX_INPUT_LEN - 1) {
          submitPairingCode(edidCode);
        }
      });
    }
  };

  const onKeyUp = (e) => {
    const upperKey = upperCase(e.key);
    const target = e.srcElement || e.target;
    let newCode = code + upperKey;

    // If backspace pressed then remove last char
    if (upperKey === 'BACKSPACE') {
      if (code.length < MAX_INPUT_LEN) {
        const prev = target.previousSibling;
        if (prev) {
          target.disabled = true;
          prev.disabled = false;
          prev.value = '';
          prev.focus();
        }
      }

      newCode = truncate(code, code.length - 1, '');
      setCode(newCode);
      return;
    }
    // If special character pressed, ignore
    else if (!isAlphaDigit(upperKey) || upperKey.length > 1) return; 
    // If input code is more than 6 chars, ignore 
    else if (newCode.length > MAX_INPUT_LEN) return;
    // If last char is pressed, submit automatically
    else if (newCode.length === MAX_INPUT_LEN) {
      setCode(newCode);
      submitPairingCode(newCode);
      return;
    }

    // Update and set new code
    setCode(newCode);

    // Enable the next box, unless it's the last box
    const next = target.nextElementSibling;
    if (next) {
      target.disabled = true;
      next.disabled = false;
      next.focus();
    }
  };

  const renderCodeBox = (id, disabled, autofocus) => {
    return (
      <input
        id={id}
        key={id}
        className="inputBox"
        onKeyUp={onKeyUp}
        maxLength={1}
        disabled={disabled}
        autoFocus={autofocus}
      ></input>
    );
  };

  const renderCodeInput = () => {
    let boxes = [];
    for (let i = 0; i < MAX_INPUT_LEN; i++) {
      let disabled = true;
      let autofocus = false;
      
      // Only focus and enable the first box
      if (i === 0) {
        disabled = false;
        autofocus = true;
      }
      boxes.push(renderCodeBox(i, disabled, autofocus));
    }

    return (
      <div className="inputContainer">
        {boxes}
      </div>
    );
  };

  const renderStatusLabel = () => {
    let text = "Enter the PIN to Connect";
    const label = document.getElementById("label");
    if (label) {
      if (props.isLinking) {
        text = "Connecting...";
        label.style.color = '#4d4d4d';
      } else if (props.pairingError) {
        text = props.pairingError;
        label.style.color = 'red';
      }  
    }
    return <p id="label" className="pairingLabel">{text}</p>;
  };

  return (
    <div className="container">
      <p className="title">Lifesize Camera</p>
      <div className="content">
        {renderCodeInput()}
      </div>
      {renderStatusLabel()}
    </div>
  );
};

export default Pairing;
