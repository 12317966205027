import React, { useState, useEffect } from 'react';
import split from 'lodash/split';
import get from 'lodash/get';
import './App.css';
import Start from './Start';
import Confirmation from './Confirmation';
import CameraControls from './CameraControls';
import About from './About';
import SettingsContainer from '../containers/Settings';
import PairingContainer from '../containers/Pairing';
import { convertLinkingCode } from '../utils/edid';

const { ipcRenderer } = window.require('electron');

const App = (props) => {
  const [isManualPairing, setIsManualPairing] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showAbout, setShowAbout] = useState(false);

  const setPairingCodeCallback = () => {
    ipcRenderer.on('edid-passcode', (_, passcode) => {
      if (passcode !== 0 && !props.pairingCode) {
        props.setPairingCode('');
        const edidCode = convertLinkingCode(passcode);
        props.setPairingCode(edidCode);
      }
      else {
        console.log('pairing terminated!');
        // TODO: implement
      }
    });
  };
  useEffect(setPairingCodeCallback, []);

  const setShowAboutCallback = () => {
    ipcRenderer.on('show-about', (_, about) => {
      const { clientVersion } = about;
      const clientInfo = split(clientVersion, '.');
      const clientBuild = get(clientInfo, '[2]', 'N/A');

      props.setAbout({
        clientVersion,
        clientBuild
      });

      setShowAbout(true);
    });
  };
  useEffect(setShowAboutCallback, []);

  const setEnableLogsCallback = () => {
    ipcRenderer.on('enable-logs', (_, enable) => {
      localStorage.setItem('enableLogs', enable);
    });
  };
  useEffect(setEnableLogsCallback, []);

  useEffect(() => {
    if (!props.isLinked) {
      resetStates();
    }
  }, [props.isLinked]);

  useEffect(() => {
    if (!showSettings) {
      ipcRenderer.send('set-size', { width: 420, height: 400 });
    }
  }, [showSettings]);

  const resetStates = () => {
    setIsManualPairing(false);
    setShowSettings(false);
    setShowControls(false);
    setShowAbout(false);
  };

  const renderAboutModal = () => {
    if (!showAbout) return;

    return (
      <About
        isAboutModalOpen={showAbout}
        onCloseAboutModal={() => setShowAbout(false)}
        about={props.about}
      />
    );
  };

  // The logical flow of the app. Order is important.
  const renderView = () => {
    if (showControls) {
      return <CameraControls hideControls={() => setShowControls(false)}/>;
    }
    if (showSettings) {
      return <SettingsContainer
        isWrtcConnected={props.isWrtcConnected}
        togglePip={props.togglePip}
        requestState={() => props.requestState(props.uuid)}
        showControls={() => setShowControls(true)}
        roomName={props.roomName}
      />;
    }
    if (props.isWrtcConnected && props.isLinked) {
      return <Confirmation
        onNext={() => setShowSettings(true)}
        roomName={props.roomName}
      />;
    }
    if (props.pairingCode || isManualPairing) {
      return <PairingContainer code={props.pairingCode}/>;
    }
    return <Start onManualPairing={() => setIsManualPairing(true)}/>;
  };

  return (
    <div className="container">
      <div className="header">
        <header className="text">Lifesize Connect</header>
      </div>
      {renderView()}
      {renderAboutModal()}
    </div>
  )
};

export default App;
