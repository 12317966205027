import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import './Settings.css';
import AudioMonitor from './AudioMonitor';
import mic from '../assets/mic.svg';

const { ipcRenderer } = window.require('electron');

const winWidth = 420;
const winHeight = 690;
const micLevelLightCount = 10;
let monitor;

const Settings = (props) => {
  const [micLevel, setMicLevel] = useState(0);
  const [isMuted, setIsMuted] = useState(true);
  const [audioMonitorSuspended, setAudioMonitorSuspended] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDisconnecting, setIsDisconnecting] = useState(false);

  useEffect(() => {
    ipcRenderer.send('set-size', { width: winWidth, height: winHeight });
  }, []);

  useEffect(() => {
    const remoteVideo = document.getElementById('remoteVideo');
    if (!remoteVideo.srcObject && props.isWrtcConnected && props.stream) {
      remoteVideo.srcObject = props.stream;
      remoteVideo.defaultMuted = true;
      remoteVideo.muted = true;
    }
  }, [props.isWrtcConnected, props.stream]);

  useEffect(() => {
    monitor = new AudioMonitor(
      value => onAudioMonitorUpdate(value),
      () => setAudioMonitorSuspended(true)
    );
    connectAudioMonitor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      // Clean up audio monitor on component unmounted
      monitor.destroy();
    }
  }, []);

  const toggleAudioMute = () => {
    const shouldMute = !isMuted;
    
    const remoteVideo = document.getElementById('remoteVideo');
    remoteVideo.muted = shouldMute;

    if (shouldMute) {
      monitor.stop();
    } else {
      monitor.start();
    }

    setIsMuted(shouldMute);

    // Start audio monitor if needed
    if (!shouldMute && audioMonitorSuspended) {
      startAudioMonitor();
    }
  }

  const connectAudioMonitor = () => {
    monitor.connect(props.stream);
  };

  const startAudioMonitor = async () => {
    await monitor.audioContext.resume();
    setAudioMonitorSuspended(false);
  };

  const onAudioMonitorUpdate = (value) => {
    const normalizedMicLevel = (micLevelLightCount * value).toFixed(0);
    if (normalizedMicLevel !== micLevel) {
      setMicLevel(normalizedMicLevel);
    }
  }

  const onOpenModal = () => {
    setIsModalOpen(true);
  }

  const onCloseModal = () => {
    setIsModalOpen(false);
  }

  const onAfterCloseModal = () => {
    setIsDisconnecting(false);
  }

  const onDisconnect = () => {
    setIsDisconnecting(true);
    props.unlinkBegin();
    props.stopPresentation();
    props.disconnect(props.uuid);
  }

  const renderLabel = (text) => {
    return (
      <div className="labelContainer">
        <p className="label">{text}</p>
      </div>
    );
  };

  const micLevelLights = () => {
    const icons = new Array(micLevelLightCount);
    icons.fill(false);
    icons.fill(true, 0, micLevel);
    return icons;
  };

  const renderMicrophoneLevels = () => {
    const buttonTitle = isMuted ? 'Test' : 'Stop';

    return (
      <span className="audioLevelContainer">
        <img className="micIcon" src={mic} alt="svg not found" width="32px" height="32px" />
        {micLevelLights().map((isOn, index) => {
          const className = isOn ? "audioSquareOn" : "audioSquareOff";
          return <i key={index} className={className} />;
        })}
        <button className="testButton" onClick={toggleAudioMute}>{buttonTitle}</button>
      </span>
    );
  };

  const renderVideo = () => {
    return (
      <video
        id="remoteVideo"
        className="settingsContent"
        height="240"
        width="320"
        playsInline={true}
        autoPlay={true}>
      </video>
    );
  };

  // const renderControlsButton = () => {
  //   return <button className="controlsButton" onClick={() => {
  //     props.showControls();
  //     // props.requestState();
  //     // props.togglePip();
  //   }}>Controls</button>;
  // };

  const renderDisconnectButton = () => {
    return (
      <button
        className="controlsButton"
        onClick={onOpenModal}
      >
        Disconnect
      </button>
    );
  };

  const renderDisconnectModalButton = () => {
    const text = isDisconnecting ? 'Disconnecting...' : 'Disconnect';

    return (
      <button
        className="disconnectButton"
        onClick={onDisconnect}
        disabled={isDisconnecting}
      >
        {text}
      </button>
    );
  };

  const renderDisconnectModal = () => {
    return (
      <Modal
        className="modal"
        overlayClassName="modalOverlay"
        isOpen={isModalOpen}
        onRequestClose={onCloseModal}
        onAfterClose={onAfterCloseModal}
        shouldCloseOnOverlayClick={false}
      >
        <p className="modalTitle">Disconnect your computer from</p>
        <p className="modalTitle">{props.roomName}</p>
        <div className="buttonsContainer">
          <button className="cancelButton" onClick={onCloseModal}>Cancel</button>
          {renderDisconnectModalButton()}
        </div>
      </Modal>
    );
  };

  return (
    <div className="settingsContainer">
      <div className="titleContainer">
        <p className="settingsTitle">{props.roomName}</p>
      </div>
      <p className="subtitle">Audio/Video Settings</p>
      {renderLabel('Icon 700 Microphone')}
      {renderMicrophoneLevels()}
      {renderLabel('Icon 700 Camera')}
      {renderVideo()}
      {/* {renderControlsButton()} */}
      {renderDisconnectButton()}
      {renderDisconnectModal()}
    </div>
  );
};

export default Settings;
