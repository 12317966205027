import get from 'lodash/get';
import { nucleusConstants, servicesActions } from '@lifesize/nucleus';
import { remotingConstants } from '@lifesize/clients.remoting';
import { login } from './actions/pairing';
import { pairing, app } from './constants';

const middleware = (store) => (next) => (action) => {
  const result = next(action);

  switch (action.type) {
    case remotingConstants.pairing.actions.GET_PRESENTER_TOKEN_SUCCESS:
      login(store, get(action, 'payload.jwt.A'));
      break;
    case remotingConstants.pairing.actions.GET_PRESENTER_TOKEN_FAILED:
      if (get(action.payload, 'message') === app.NO_NETWORK) {
        store.dispatch({ type: pairing.SUBMIT_CODE_FAILURE, payload: app.NO_NETWORK_ERROR });
      } else {
        store.dispatch({ type: pairing.SUBMIT_CODE_FAILURE, payload: app.INCORRECT_PIN });
      }
      
      break;
    case nucleusConstants.INITIALIZE_SERVICES_SUCCESS:
      store.dispatch({ type: remotingConstants.remoting.actions.LIFESIZE_CONNECT_CREATE_OFFER });
      break;
    case remotingConstants.pairing.actions.WAMP_SUBSCRIPTION_LINKING_EVENT:
      const eventType = get(action, 'payload.eventType');
      const linkType = get(action, 'payload.linkType');
      const unlinkedUUID = get(action, 'payload.unlinkedUUID');
      if (eventType === remotingConstants.pairing.subscriptionEventTypes.unlinked
        && linkType === remotingConstants.pairing.linkedDeviceTypes.gemsPresenter
        && unlinkedUUID) {
          store.dispatch({ type: pairing.UNLINK_SUCCESS });
          store.dispatch(servicesActions.disconnect());
      }
      break;
    default:
      break;
  }
  return result;
};

export default middleware;
