import { createLogger } from 'redux-logger';
import { nucleusConstants, createReduxStore, initActions } from '@lifesize/nucleus';
import { remotingReducers, remotingInitialStates, remotingMiddlewares } from '@lifesize/clients.remoting';
import { ClientTypes } from '@lifesize/constants';
import autobahn from 'autobahn';
import middleware from './middleware';
import pairingReducer, { initialState as pairingInitialState } from './reducers/pairing';
import aboutReducer, { initialState as aboutInitialState } from './reducers/about';
import { getApiDomain } from './utils/url';

const actionLog = [
  nucleusConstants.MEDIA_DEVICES_UPDATED,
  nucleusConstants.CLOCK_TICK_MIN,
  nucleusConstants.CLOCK_TICK_SEC
];

export default async function createStore() {
  const initialState = {
    remoting: {
      lifesizeConnect: remotingInitialStates.lifesizeConnect,
      controller: remotingInitialStates.controller
    },
    pairing: pairingInitialState,
    about: aboutInitialState
  };
  
  const remotingReducer = (state, action) => {
    return {
      lifesizeConnect: remotingReducers.lifesizeConnect(state.lifesizeConnect, action),
      controller: remotingReducers.controller(state.controller, action)
    };
  };
  
  const reducers = (state = initialState, action) => {
    return {
      remoting: remotingReducer(state.remoting, action),
      pairing: pairingReducer(state.pairing, action),
      about: aboutReducer(state.about, action)
    };
  }

  const middlewares = [
    middleware,
    remotingMiddlewares.pairing,
    remotingMiddlewares.remoting,
    remotingMiddlewares.lifesizeConnect
  ];

  if (process.env.NODE_ENV === 'development' || localStorage.getItem('enableLogs')) {
    const logger = createLogger({
      collapsed: true,
      duration: true,
      timestamp: true,
      predicate: (_, action) => !actionLog.includes(action.type)
    });
    middlewares.push(logger);
  }

  const store = createReduxStore(
    reducers, // reducers
    middlewares, // middlewares
    initialState, // initial state
    { manualLoad: true }, // persistence
    autobahn // wamp-client
  );

  const nucleusInitOptions = {
    clientInfo: {
      clientType: ClientTypes.DASH_PRESENTER
    },
    environment: getApiDomain()
  };

  await store.dispatch(initActions.initialize(nucleusInitOptions));

  return store;
}
