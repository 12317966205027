import { remotingActions } from '@lifesize/clients.remoting';
import { pairing } from '../constants';

const settingsActions = (dispatch) => ({
    disconnect: (uuid) => dispatch(remotingActions.remoting.sendToDisplay.app.unlinkConnect(uuid)),
    stopPresentation: () => dispatch(remotingActions.lifesizeConnect.stopLifesizeConnectPresentation()),
    unlinkBegin: () => dispatch({ type: pairing.UNLINK_BEGIN })
});

export default settingsActions;
