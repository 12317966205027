import throttle from 'lodash/throttle';

const audioContextOptions = {
  latencyHint: 'playback',
  sampleRate: 8000
};

/**
 * Represents an object that can be used to monitor microphone volume.
 */
class AudioMonitor {
  /**
   * Initializes a new instance of the AudioMonitor class.
   * @param {Function} onUpdate The function to be called when there is an updated volume value
   * @param {Function} onSuspend The function to be called when AudioContext cannot be started
   */
  constructor(onUpdate, onSuspend) {
    this.audioContext = new AudioContext(audioContextOptions);
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.smoothingTimeConstant = 0.4;
    this.analyser.fftSize = 32;
    this.analyser.minDecibels = -80;
    this.analyser.maxDecibels = -10;
    this.onAudioProcess = throttle(() =>
      this.processAudio(onUpdate), 100, { leading: true, trailing: false });
    this.script = this.audioContext.createScriptProcessor(256, 1, 1);
    this.isConnected = false;
    if (this.audioContext.state === 'suspended') {
      onSuspend();
    }
    this.isRunning = false;
  }

  processAudio(onUpdate) {
    if (this.isRunning) {
      const array = new Uint8Array(this.analyser.frequencyBinCount);
      this.analyser.getByteFrequencyData(array);
      const max = (Math.max(...array) / 256).toFixed(2);
      onUpdate(max);
    } else {
      onUpdate(0);
    }
  }

  /**
   * Stop the audio monitor from processing audio data.
   */
  stop() {
    if (!this.isRunning) return;

    this.isRunning = false;
    this.script.removeEventListener('audioprocess', this.onAudioProcess);
    // Run one more time to reset to 0. Must use timeout to avoid throttling.
    setTimeout(this.onAudioProcess, 150);
  }

  /**
   * Start the audio monitor to process audio data.
   */
  start() {
    if (this.isRunning) return;

    this.isRunning = true;
    this.script.addEventListener('audioprocess', this.onAudioProcess);
  }

  async connect(stream) {
    if (this.isConnected) {
      this.disconnect();
    }
    try {
      this.stream = stream;
      this.microphone = this.audioContext.createMediaStreamSource(this.stream);
      this.microphone.connect(this.analyser);
      this.analyser.connect(this.script);
      this.script.connect(this.audioContext.destination);
      this.isConnected = true;
    }
    catch (e) {
      console.log(`AudioMonitor: Error trying to connect to device: ${e}`);
    }
  }

  /**
   * The disconnect method disconnects the audio monitor from the previously connected microphone
   */
  disconnect() {
    if (this.stream) {
      this.stream.getAudioTracks().forEach(t => t.stop());
    }
    if (this.microphone) {
      this.microphone.disconnect();
    }
    this.analyser.disconnect();
    this.script.disconnect();
    this.isConnected = false;
  }

  /**
   * The destroy method disconnects and closes the audio monitor
   */
  async destroy() {
    this.script.removeEventListener('audioprocess', this.onAudioProcess);
    this.disconnect();
    await this.audioContext.close();
  }
}

export default AudioMonitor;
