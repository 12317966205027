import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppContainer from './containers/App';
import { init } from './init';
import './index.css';

const start = async () => {
  const store = await init();

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <AppContainer />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

start();
