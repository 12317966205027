import React, { useEffect } from 'react';
import './Confirmation.css';
import check from '../assets/check.svg';

const Confirmation = (props) => {
  useEffect(() => {
    // Move to the next page after 3 seconds
    const nextTimer = setTimeout(() => props.onNext(), 3000);
    return () => clearTimeout(nextTimer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <p className="title">{props.roomName}</p>
      <div className="content">
        <div className="circle"></div>
        <img className="check" src={check} alt="svg not found" width="30px" height="30px" />
      </div>
      <p className="successLabel">Successfully Connected</p>
      <button className="button" onClick={props.onNext}>Done</button>
    </div>
  );
}

export default Confirmation;
